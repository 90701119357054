/*
 * 업무구분: 활동
 * 화 면 명: MSPAP311P
 * 화면설명: 전화활동 검색
 * 작 성 일: 2025.02.24
 * 작 성 자: 최종환
 */
<template>
  <ur-page-container title="검색" :show-title="true" type="subpage" class="msp myCallHistory">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- scroll 영역 -->
          <template #scroll>

            <!-- 관리자 기본검색필터 -->
            <ur-box-container direction="column" alignV="start" class="msp-ap-wrap" v-if="isManager">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-add-area mb30">
                <ur-box-container alignV="start" componentid="" direction="column" class="">
                  <msp-bottom-select class="ns-dropdown-sheet" :items="hofOrgData" v-model="hofOrg.key" @input="fn_GetDofData" :disabled="orgAuth.isEntpwRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="사업부선택" closeBtn scrolling placeholder="사업부선택"/>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                  <msp-bottom-select class="ns-dropdown-sheet" :items="dofOrgData" v-model="dofOrg.key" @input="fn_GetFofData" :disabled="orgAuth.isHofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지역단선택" closeBtn scrolling placeholder="지역단선택"/>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                  <msp-bottom-select class="ns-dropdown-sheet" ref="refFofOrgData" :items="fofOrgData" v-model="fofOrg.key" @input="fn_GetFofCnsltData" :disabled="orgAuth.isDofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지점선택" closeBtn scrolling placeholder="지점선택"/>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                  <msp-bottom-select 
                    class="ns-dropdown-sheet" 
                    ref="refFofCnsltData" 
                    :items="fofCnsltData" 
                    v-model="fofCnslt.key" 
                    itemHeight="374" 
                    :itemValue="'key'" 
                    :itemText="'label'" 
                    underline 
                    bottom-title="컨설턴트 선택" 
                    closeBtn 
                    scrolling 
                    placeholder="컨설턴트 선택"
                    />
                </ur-box-container>

              </ur-box-container>
            </ur-box-container>
            <!-- //관리자 기본검색필터 -->

            <!-- 탭(고객명/메모) 구조 검색 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1">
              <!-- 탭(고객명/메모) -->
              <div class="ns-segment myCallHistoryWrap">
                <mo-segment-wrapper solid primary v-model="searchType">
                  <mo-segment-button value="고객명 검색">고객명</mo-segment-button>
                  <mo-segment-button value="메모 검색">메모</mo-segment-button>
                </mo-segment-wrapper>
                <mo-text-field 
                  v-model="searchText"
                  ref="searchTextInput" 
                  searchable 
                  :placeholder="searchType" 
                  underline 
                  class="sch-input no-sch myCallHistorySearch"
                  @keyup="() => { searchText = getSearchKeyword }"
                  @keyup.enter="fn_OnClickSearch"
                  @click-icon="fn_OnClickSearch"
                  />
              </div>
              <!-- //탭(고객명/메모) -->

              <div class="myCallHistoryTotalWrap">'<span>{{searchResultText}}</span>' 검색 결과 총<span class="myCallHistoryTotal">{{searchCount}}</span>건</div>

              <!-- 정렬선택 (최신순보기/수신만보기/발신만보기) -->
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3">
                    <div class="fexTy1"></div>
                    <msp-bottom-select 
                      ref="bottomSelect" 
                      :items="sortCdList" 
                      v-model="sortCd.value" 
                      @:itemValue="'value'" 
                      :itemText="'text'" 
                      underline class="ns-dropdown-sheet maxW130 type-1 fs14rem" 
                      placeholder="정렬선택" 
                      bottom-title="정렬선택" 
                      closeBtn/>
                  </div>
                </div>
              </mo-list-item>
              <!-- //정렬선택 (최신순보기/수신만보기/발신만보기) -->

              <!-- 검색결과 노출영역 -->
              <div v-for="(obj, idx) in searchTelHistoryList" :key="idx" class="myCallHistoryWrap">
                <div class="myCallHistorySearchDate">{{obj.formattedDate}}</div>
                <div v-for="(item, index) in obj.data" :key="index" class="itemBox" :class="{'gray':item.SR==='발신','blue':item.SR==='수신'}">
                  <div class="myCallHistoryNameWrap">
                    <span class="myCallHistoryName" @click="fn_OpenCustNmUiCard(item)">{{item.name}}</span>
                    <div class="myCallHistoryBtnWrap" v-if="isOwnerSearch">
                      <mo-button class="blackWH myCallHistoryBtnModify" @click="fn_SaveMemo(item)">{{item.memo ? '수정' : '입력' }}</mo-button>
                      <mo-icon icon="msp-close-bold" class="myCallHistoryBtnClose" @click="fn_BottomConfirm('삭제하시겠습니까?', item.CheckSn, item.chnlCustId )">msp-close-bold</mo-icon>
                    </div>
                  </div>
                  <div class="mt5">
                    <span class="myCallHistorySR" :class="{'myCallHistorySRBlue':item.SR==='수신'}">{{item.SR}}</span>
                    <span class="myCallHistoryTime">{{item.CntcCntnt}}</span>
                  </div>
                  <div class="myCallHistoryMemo" v-if="item.memo" v-html="item.memo"></div>
                </div>
              </div>
              <!-- //검색결과 노출영역 -->

              <!-- 검색결과 미존재 시 -->
              <ur-box-container v-if="isEmptySearchResult" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data mt50">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info small-space h200px"> 
                      <span class="ns-ftcr-gray full">
                        <b class="fwn crTy-blue2 mt10">{{searchResultText}}</b>에 대한 검색결과가 없습니다.
                      </span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              <!-- 검색결과 미존재 시 //-->

            </ur-box-container>
            <!-- //탭(고객명/메모) 구조 검색 -->

            <!-- 하단 버튼 영역(초기화/조회) -->
            <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
              </div>
            </ur-box-container>
            <!-- //하단 버튼 영역(초기화/조회) -->
          </template>

        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM139P from '@/ui/cm/MSPCM139P' //전화메모 추가/수정 팝업
import MSPAP312P from '@/ui/ap/MSPAP312P' //고객명 검색 팝업
import apConstants from '@/config/constants/apConstants'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPAP311P',
  // 현재 화면 ID
  screenId: 'MSPAP311P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    MSPCM139P, //전화메모 추가/수정 팝업
    MSPAP312P, //고객명 검색 팝업
  },
  // 화면명
  props: {
    pPage: String, // 부모 페이지명
    pIsMngr: Boolean, // 지점장여부
    fcDtlData: '',
  },
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      //영업 관리자
      managerSearchParam: { 
        whlInqrYn: 'Y', 
        hofOrgNo: '',
        dofOrgNo: '',
        fofOrgNo: '',
        orgNo: '', 
        cnsltInqrYn: '' 
      }, 
      
      // 검색조건
      hofOrg: {key: '00000000', label: '전사'}, // 선택사업부
      dofOrg: {key: '0', label: '전체'}, // 선택지역단
      fofOrg: {key: '0', label: '전체'}, // 선택지점
      fofCnslt: {key: '0', label: '전체'}, // 선택컨설턴트

      hofOrgData: [], // 사업부 목록
      dofOrgData: [], // 지역단 목록
      fofOrgData: [], // 지점 목록
      fofCnsltData: [], // 컨설턴트 목록
      orgAuth: {}, // 지점권한

      // 공통 변수
      userInfo : {},

      // 조회 변수
      searchType : '고객명 검색', // 디폴트 탭 (고객명/메모)
      searchText : '', // 검색 키워드
      searchCount : 0, // 검색 결과 숫자 표시
      searchResultText : '', // 검색 결과 키워드

      isLoading : false,
      searchTelHistoryList : [], // 고객명 > 채널고객ID로 조회한 검색결과 목록
      originSearchTelHistoryList : [], // 고객명 > 채널고객ID로 조회한 검색결과 목록

      selectedCustChnlCustId : '', // 고객명 검색결과 고객List에서 컨설턴트가 선택한 채널고객ID
      isCustomerSelected : false, //고객선택 팝업에서 고객 선택 여부

      isSearchCustomerActionDone : false, //채널고객ID 고객검색 API 호출여부
      isSearchMemoActionDone : false, //메모 고객검색 API 호출여부

      cnsltNo : '', //컨설턴트 사번

      popUp312 : {}, //고객명 검색 팝업

      /* 계약 정렬 선택값 */
      sortCdList : apConstants.CALL_ACTIVITY_SORT_CD_LIST,
      sortCd : {}, // 선택된 정렬 데이터// 조회 FC

      isRoleCheckFirst: true,
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    /**
     * 현재 전화활동 내역을 조회한 컨설턴트가 전화활동을 수행한 컨설턴트 본인인지 확인
     * @returns {boolean}
     */
    isOwnerSearch () {
      if (this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
        return false
      } else {
        return true
      }
    },
    /**
     * 지점장 이상 권한인지 확인
     * @returns {boolean}
     */
    isManager () {
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        return true
      } else {
        return false
      }
    },
    /**
     * 고객명 검색 시 입력한 검색어 대문자 치환 후 리턴. 메모 검색 시 입력한 검색어 그대로 리턴
     * 영문 고객명의 경우 대문자로만 등록됨
     * 메모의 경우 메모 내용 중 소문자 존재 가능
     * @returns {string}
     */
    getSearchKeyword() {
      if (this.searchType == '고객명 검색') {
        return this.searchText?.replace(/\s|-/g, '').toUpperCase() || ''
      } else {
        return this.searchText
      }
    },
    /**
     * 고객명/메모 검색결과가 비어있는지 확인
     * @returns {boolean}
     */
    isEmptySearchResult () {
      if (this.isSearchCustomerActionDone == true || this.isSearchMemoActionDone == true) {
        if (this.searchType == '고객명 검색') {
          //관리자
          if (this.isManager == true) { 
            if (this.searchTelHistoryList.length <= 0) {
              return true
            } else {
              return false
            }
          } else {
            //일반 컨설턴트
            if ( this.isCustomerSelected && this.isSearchCustomerActionDone ) {
              if (this.searchTelHistoryList.length <= 0) {
                return true
              } else {
                return false
              }
            }
            return false
          }
        } else if (this.searchType == '메모 검색') {
          if (this.isSearchMemoActionDone) {
            if (this.searchTelHistoryList.length <= 0) {
              return true
            } else {
              return false
            }
          }
        }
      } else {
        return false
      }
    },
  },
  
  /** watch 정의 영역 */
  watch: {
    /**
     * 검색어 변경 시 호출됩니다.
     */
    searchText: {
      immediate: true,
      handler(val) {
        if (this.DEBUG) console.log('searchText length', val.length)

        if (this.getSearchKeyword.length < 1) {
          this.fn_InitVariables() // 화면초기화
        }
      }
    },
    /**
     * 전화활동 검색 정렬선택 필터 값이 변경되면 호출됩니다.
     * @param {string} value - 정렬선택 값 (1:시간순 보기, 2:최신순 보기, 3:수신만 보기, 4:발신만 보기)
     */
    'sortCd.value'(value) {
      console.log('watch sortCd.value >> value', value)

      let selSortCd = null
      selSortCd = this.sortCdList.filter(item => {
        return item.value === value
      })
      this.fn_GetSortListBySortCd(selSortCd[0])

      if (this.searchType == '메모 검색') {
        if (this.searchTelHistoryList.length > 0) {
          this.fn_RevealSearchWord(this)
        }
      }
    },
    /**
     * 영업 관리자 로그인 후 컨설턴트 변경 시 호출됩니다.
     * @param {string} value - 변경된 컨설턴트 사번
     */
    'fofCnslt.key'(value) {
      console.log('watch fofCnslt.key >> value', value)

      let fofCnslt = this.fofCnsltData.filter(item => {
        return item.key === value
      })[0]

      if ( !this.$bizUtil.isEmpty(fofCnslt) ) {
        this.fofCnslt.key = fofCnslt.key
        this.fofCnslt.label = fofCnslt.label
        this.cnsltNo = this.fofCnslt.key

        console.log('fofCnslt.key >> filtered fofCnslt', fofCnslt)
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.fn_InitVariables()

    /**
     * 전화활동 검색 팝업 오픈시 전달되는 FC정보를 근거로 사업부/지역단/지점/컨설턴트 정보 세팅
     */

    let fcDtlData = this.$props.fcDtlData //전화활동 검색 팝업 오픈시 전달되는 FC정보

    console.log('created fcDtlData', fcDtlData)

    //컨설턴트 (key, label) 세팅
    if ( !this.$bizUtil.isEmpty(this.$props.fcDtlData) 
        && !this.$bizUtil.isEmpty(this.$props.fcDtlData.key) 
        && Object.keys(this.$props.fcDtlData).length !== 0 ) {
      this.fofCnslt.key = this.$props.fcDtlData.key
      this.fofCnslt.label = this.$props.fcDtlData.label
    } else {
      this.fofCnslt.key = this.getStore('userInfo').getters.getUserInfo.userId
      this.fofCnslt.label = this.getStore('userInfo').getters.getUserInfo.userNm
    }

    //전화활동 검색 팝업 컨설턴트사번 세팅
    this.cnsltNo = this.fofCnslt.key

    //전화활동 검색 팝업 컨설턴트 조직정보 세팅
    if ( !this.$bizUtil.isEmpty(fcDtlData) 
        && !this.$bizUtil.isEmpty(fcDtlData.hofOrgNo) 
        && Object.keys(fcDtlData).length !== 0 ) {
      this.hofOrg = fcDtlData.hofOrg
      this.dofOrg = fcDtlData.dofOrg
      this.fofOrg = fcDtlData.fofOrg
    } else {
      this.hofOrg.key = this.getStore('userInfo').getters.getUserInfo.hofOrgNo
      this.dofOrg.key = this.getStore('userInfo').getters.getUserInfo.dofOrgNo
      this.fofOrg.key = this.getStore('userInfo').getters.getUserInfo.fofOrg
    }

    console.log('created this.userInfo', this.userInfo)
    console.log('created this.cnsltNo', this.cnsltNo)
    console.log('created this.fofCnslt', this.fofCnslt)
    console.log('created this.fcDtlData', this.fcDtlData)
    console.log('created this.$props.fcDtlData', this.$props.fcDtlData)
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPAP311P')

    switch (this.pPage) {
      // 전체고객
      case 'MSPAP310M':
        this.searchCustNm = this.pSearchCustNm
        this.searchMemo = this.pSearchMemo
        this.fn_GetFofRol()
        break
    }
  },

  beforeDestroy() {
    console.log('beforeDestroy', this.fcDtlData, this.fofCnslt)
    this.$emit('onPopupClose', this.fofCnslt, 'beforeDestroy')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /**
     * 로컬 변수 초기화
     */
    fn_InitVariables () {
      console.log('fn_InitVariables')

      this.searchText = ''
      this.searchResultText = ''
      this.searchTelHistoryList = []
      this.originSearchTelHistoryList = []
      this.searchCount = 0

      this.isSearchMemoActionDone = false
      this.isSearchCustomerActionDone = false

      this.isLoading = false
      this.isCustomerSelected = false
      this.selectedCustChnlCustId = ''

      this.sortCd = this.$bizUtil.cloneDeep(this.sortCdList[0])
    },
    /**
     * 고객 전화활동 이력 중 수신/발신 내역 필터링
     * @param {object} origin - 조회된 고객 전화활동 이력 원본 데이터
     */
    fn_GetFilteredList (origin) {
      console.log('fn_GetFilteredList', 'origin', origin)

      var filtered = []
      origin.forEach(item => {
        if (item.mobslCustCntcId === 'HC08' 
            || item.mobslCustCntcId === 'HC10' 
            || item.mobslCustCntcId === 'HC11' 
            || item.mobslCustCntcId === 'SEND' 
            || item.mobslCustCntcId === 'RECI') 
        {
          let cntcId = ''
          if (item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'SEND') cntcId = '발신'
          if (item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'RECI') cntcId = '수신'

          let phclMemoCntnt = ''
          if (!this.$bizUtil.isEmpty(item.phclMemoCntnt)) {
            phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          }
          
          let tmpObj = {
            'name'             : item.custNm,
            'CntcCntnt'        : item.mobslCntcCntnt,
            'memo'             : phclMemoCntnt,
            'memoOrigin'       : item.phclMemoCntnt,
            'SR'               : cntcId,
            'CheckSn'          : item.mobslCntcCheckSn,
            'chnlCustId'       : item.mobslChnlCustId,
            'mobslCntcOccuYmd' : item.mobslCntcOccuYmd,
            'mobslCntcTm'      : item.mobslCntcTm,
          }

          filtered.push(tmpObj)
        }
      });
      return filtered
    },
    /**
     * 돋보기 버튼 클릭 시 고객명/메모 탭을 반영하여 고객명/메모 검색 로직 동적 수행
     */
    fn_OnClickSearch () {
      console.log('fn_OnClickSearch', 'searchType', this.searchType, 'isManager', this.isManager)
      if (this.searchType == '고객명 검색') {
        console.log('fn_OnClickSearch 고객명 검색')

        //고객명
        if (this.isManager) {
          //관리자인 경우, 특정 FC만 선택 후 검색 시 전화활동 홈 화면에서 검색
          if (this.searchText.trim() === '') {
            console.log('fn_OnClickSearch 관리자인 경우, 특정 FC만 선택 후 검색 시 전화활동 홈 화면에서 검색')
            this.$emit('onPopupClose', null, 'onClickSearch')
            return
          }
        } else {
          //일반 사용자인 경우, 고객명 없이 검색 시 얼럿 노출
          if (this.searchText.trim() === '') {
            console.log('fn_OnClickSearch 일반 사용자인 경우, 고객명 없이 검색 시 얼럿 노출')
            this.fn_BottomAlert('고객명을 입력하세요.')
            return
          }
        }

        this.fn_OpenMSPAP312P()
      } else if (this.searchType == '메모 검색') {
        console.log('fn_OnClickSearch 메모 검색')

        //메모 
        this.fn_Search()
      }
    },
    /**
     * MSPAP312P 고객선택 팝업 열기/닫기
     */
    fn_OpenMSPAP312P () {
      console.log('fn_OpenMSPAP312P')
      
      this.isCustomerSelected = false

      var cnsltNo = this.getStore('userInfo').getters.getUserInfo.userEno
      if (this.isManager) {
        cnsltNo = this.fofCnslt.key
        console.log('영업관리자인 경우 선택한 컨설턴트사번 변수에 할당 >> cnsltNo : ', cnsltNo)
      }

      let lv_Vm = this
      lv_Vm.popUp312 = lv_Vm.$bottomModal.open(MSPAP312P, {
        properties: {
          pCnsltNo: cnsltNo,
          pCustNm: this.searchText
        },
        listeners: {
          confirmPopup: (pData) =>{
            lv_Vm.$bottomModal.close(lv_Vm.popUp312)
            this.lv_SearchData = {}
            Object.assign(this.lv_SearchData, pData)

            lv_Vm.fn_ConfirmMSPAP312P(this.lv_SearchData)
          }
        }
      })
    },
    /**
     * 고객선택 팝업창에서 확인/닫기 버튼 클릭 시 호출
     * 팝업창 닫고, 팝업에서 넘어온 채널 고객 ID 설정
     * 고객선택과 무관하게 닫기 버튼 클릭 시 isExistCustYN = 'N' 객체 리턴 
     * @param {object} param - custCardVO
     */
    fn_ConfirmMSPAP312P (param) {
      console.log('fn_ConfirmMSPAP312P', param)
      
      if (param != null && param.hasOwnProperty('isExistCustYN') && param.isExistCustYN == 'N') {
        console.log('고객선택과 무관하게 닫기 버튼 클릭한 경우')
        return
      }

      if (param != null) {
        this.isCustomerSelected = true

        this.selectedCustChnlCustId = param.chnlCustId
        this.searchText = param.custNm
        this.searchResultText = param.custNm

        this.fn_Search()
      }
    },
    /**
     * memo text에 검색단어를 파란색으로 굵게 표시한다
     */
    fn_RevealSearchWord () {
      console.log('fn_RevealSearchWord')

      // memo text에 검색단어를 파란색으로 굵게 표시한다
      if (this.searchText.length === 0 || this.searchText.trim() === '') return

      if (this.searchTelHistoryList?.length) {
        for (let i = 0; i < this.searchTelHistoryList.length; i++ ) {
          for (let k = 0; k < this.searchTelHistoryList[i].data.length; k++ ){
            if (this.searchTelHistoryList[i].data[k].memo) {
              let resetResult = this.searchTelHistoryList[i].data[k].memo.replaceAll('<i>', '') // 기존 검색 결과 표시 <i>는 삭제
              this.searchTelHistoryList[i].data[k].memo = resetResult
              resetResult = this.searchTelHistoryList[i].data[k].memo.replaceAll('</i>', '')
              this.searchTelHistoryList[i].data[k].memo = resetResult

              // <i></i> tag로 감싸서 표시해 준다 (<i> tag - css는 별도 존재)
              /*
              //퍼블리싱 원본 (정확히 일치하는 키워드만 하이라이트 처리, 대소문자 구분)
              let searchResult = this.searchTelHistoryList[i].data[k].memo.replaceAll(this.searchText, '<i>' + this.searchText + '</i>')
              this.searchTelHistoryList[i].data[k].memo = searchResult
              */

              // 한글 / 영문(대소문자 구분 없이) 하이라이트 처리
              const memo = this.searchTelHistoryList[i].data[k].memo
              this.searchTelHistoryList[i].data[k].memo = this.fn_Highlightkeyword(memo, this.searchText)
            }
          }
        }
      }
    },
    /**
     * 한글/영문(대소문자 구분 없이) 하이라이트 처리 - <i></i> tag로 감싸서 표시 (<i> tag - css는 별도 존재)
     * @param text - 메모내용
     * @param keyword - 검색어
     */
    fn_Highlightkeyword(text, keyword) {
      const normalizedText = text
      const lowerText = text.toLowerCase()
      const lowerKeyword = keyword.toLowerCase()

      const regex = new RegExp(lowerKeyword, 'g')

      let result = ''
      let lastIndex = 0

      const matches = [...lowerText.matchAll(regex)]

      // text에서 keyword가 포함된 부분의 정보를 배열로 리턴
      // ex) [Array(1), Array(1)]
      // Array(1) : ['메모', index: 2, input: '수신메모123<br/>전화메모 수정', groups: undefined]
      // Array(1) : ['메모', index: 14, input: '수신메모123<br/>전화메모 수정', groups: undefined]

      matches.forEach((match) => {
        const start = match.index
        const end = start + keyword.length
        const matchedText = normalizedText.slice(start, end)

        result += normalizedText.slice(lastIndex, start) + `<i>${matchedText}</i>`
        lastIndex = end
      })

      result += normalizedText.slice(lastIndex)
      return result
    },

    /**
     * 입력값 모두 초기화 (초기 값으로 세팅)
     */
    fn_Clear () {
      console.log('fn_Clear')
      switch (this.pPage) {
        // 전체고객
        case 'MSPAP310M':
          this.fn_InitVariables()
          break
      }
    },
    /**
     * 메모저장
     */
    fn_SaveMemo(item) {
      console.log('fn_SaveMemo', 'item', item)

      if (this.isManager) {
        console.log('영업관리자 >> 수정 및 삭제가 불가능합니다.')
        this.fn_BottomAlert('수정 및 삭제가 불가능합니다.')
        return
      }

      let popup139 = this.$bottomModal.open(MSPCM139P, {
        properties: {
          pMobslCntcCheckSn: item.CheckSn,
          pChnlCustId      : item.chnlCustId,
          custNm           : item.name,
          showTime         : item.CntcCntnt,
          memoCntnt        : item.memoOrigin
        },

        listeners: {
          onPopupClose: () => {
            // 모달 닫기
            console.log('MSPCM290D onPopupClose')
            this.$bottomModal.close(popup139)
          },

          onPopupConfirm: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(popup139)
            this.fn_Search()
          },
        }
      },
      {
        properties: {
          preventTouchClose: true,
        }
      })
    },
    /**
     * 전화메모 단건 삭제
     */
    fn_DeleteMemo(CheckSn, chnlCustId) {
      console.log('fn_DeleteMemo', 'CheckSn', CheckSn, 'chnlCustId', chnlCustId)

      const lv_Vm   = this 
      const trnstId = 'txTSSCM58D3' 
      const auth    = 'S'

      let pParams = {
        mobslCntcCheckSn: CheckSn,
        mobslChnlCustId : chnlCustId,
        mobslCnsltEno   : this.userInfo.userId,
      }
      console.log('fn_DeleteMemo param>>!', pParams)

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response?.body && response.body.rsltYn === '1') {
            console.log('fn_DeleteMemo 삭제완료')
            lv_Vm.fn_Search()
          } else {
            let msg = response.msgComm.msgDesc
            lv_Vm.$cmUtil.fn_OpenAlert(true, msg)
          }
        })
    },
    /**
     * Confirm 팝업 호출(확인, 취소)
     */
    fn_BottomConfirm (contents, CheckSn, chnlCustId) {
      console.log('fn_BottomConfirm', 'contents', contents, 'CheckSn', CheckSn, 'chnlCustId', chnlCustId)

      if (this.isManager) {
        this.fn_BottomAlert('수정 및 삭제가 불가능합니다.')
        return
      }

      this.lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title        : '알림',
          content      : contents,
          title_pos_btn: "삭제",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_ConfirmPop);
            this.fn_DeleteMemo(CheckSn, chnlCustId)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_ConfirmPop);
          }
        }
      })
    },
    /**
     * 고객명/메모 탭 Input 값 기준으로 전화활동 검색
     */
    fn_Search () {
      console.log('fn_Search', 'searchType', this.searchType)

      if (this.searchType === '고객명 검색') {
        this.fn_SearchByCustNm()
      } else if (this.searchType === '메모 검색') {
        this.fn_SearchByMemo()
      }
    },
    /**
     * 고객명 탭 선택 후 조회 시 실행 : 고객명 Input 값 기준으로 전화활동 검색
     */
    fn_SearchByCustNm() {
      console.log('fn_SearchByCustNm', 'isManager', this.isManager, 'searchText', this.searchText, 'isCustomerSelected', this.isCustomerSelected)
      
      if (this.isManager) {
        //관리자가 특정 FC만 선택하는 경우 전화활동에서 조회
        if (this.searchText == null || this.searchText.trim() == '') {
          console.log('fn_SearchByCustNm 관리자가 특정 FC만 선택하는 경우 전화활동에서 조회')
          this.$emit('onPopupClose', null, 'searchByCustNm')
          return
        }
      } else {
        if (this.searchText == null || this.searchText.trim() == '') {
          console.log('fn_SearchByCustNm 고객명을 입력하세요.')
          this.fn_BottomAlert('고객명을 입력하세요.')
          return
        }

        if (this.isCustomerSelected == false) {
          console.log('fn_SearchByCustNm 고객선택 팝업에서 고객을 선택하세요.')
          this.fn_BottomAlert('고객선택 팝업에서 고객을 선택하세요.')
          return
        }
      }

      const lv_Vm   = this
      const trnstId = 'txTSSAP06S2' //전화문자연결이력 조회 (일자조건 무시)
      const auth    = 'S'

      var cnsltNo = this.getStore('userInfo').getters.getUserInfo.userEno
      if (this.isManager) {
        cnsltNo = this.fofCnslt.key
        console.log('fn_SearchByCustNm 영업관리자인 경우 선택한 컨설턴트사번 변수에 할당 >> cnsltNo : ', cnsltNo)
      }

      let searchParams = this.fn_SearchParamBuilder()
          .setEno(cnsltNo) //모바일영업컨설턴트사번
          .setFlagMobslChnlCustId(1) //모바일영업채널고객ID 필터링 적용여부
          .setChnlCustId(this.selectedCustChnlCustId)//모바일영업채널고객ID
          .setFlagMobslCntcOccuYmd(0) //모바일영업접촉발생일자 필터링 적용여부
          .setMobslCntcOccuYmd('') //모바일영업접촉발생일자
          .setFlagPhclMemoCntnt(0) //통화메모내용 필터링 적용여부
          .setPhclMemoCntnt('') //통화메모내용
          .setFlagMobslCustCntcId(0) //발신/수신/전체 필터링 적용여부
          .build()

      console.log('fn_SearchByCustNm searchParams>>!', searchParams)

      this.isLoading = true
      this.isSearchCustomerActionDone = false

      this.post(lv_Vm, searchParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null) {
              if (response.body !== null && response.body.telHstrVOMap !== null) {
                let telHistoryObj = response.body.telHstrVOMap

                lv_Vm.searchCount = 0
                lv_Vm.searchTelHistoryList = [] //초기화
                lv_Vm.originSearchTelHistoryList = [] //초기화

                for (const key in telHistoryObj) {
                  let telHistoryListByDate = telHistoryObj[key]
                  const filteredListByDate = lv_Vm.fn_GetFilteredList(telHistoryListByDate)
                  if (filteredListByDate.length > 0) {
                    if (key.length == 8) { //key : yyyymmdd value
                      var telHistoryObjByDate = {}
                      var yyyymmdd = lv_Vm.fn_GetFormattedDateString(key)

                      telHistoryObjByDate['date'] = key
                      telHistoryObjByDate['formattedDate'] = yyyymmdd
                      telHistoryObjByDate['data'] = filteredListByDate
                      lv_Vm.searchTelHistoryList.push(telHistoryObjByDate)
                      lv_Vm.originSearchTelHistoryList.push(telHistoryObjByDate)

                      lv_Vm.searchCount += filteredListByDate.length
                    }
                  }
                }

                console.log('fn_SearchByCustNm', 'sortCd', lv_Vm.sortCd, 'searchTelHistoryList', lv_Vm.searchTelHistoryList)

                lv_Vm.fn_GetSortListBySortCd(lv_Vm.sortCd)
              }
            }
            lv_Vm.isLoading = false
            lv_Vm.isSearchCustomerActionDone = true
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },
    /**
     * 메모 탭 선택 후 조회 시 실행 : 메모 Input 값 기준으로 전화활동 검색
     */
    fn_SearchByMemo() {
      console.log('fn_SearchByMemo', 'isManager', this.isManager, 'searchText', this.searchText)
      
      if (this.isManager) {
        //관리자가 특정 FC만 선택하는 경우 전화활동에서 조회
        if (this.searchText.trim() === '') {
          console.log('fn_SearchByMemo 관리자가 특정 FC만 선택하는 경우 전화활동에서 조회')
          this.$emit('onPopupClose', null, 'searchByMemo')
          return
        }
      } else {
        if (this.searchText.trim() === '') {
          console.log('fn_SearchByMemo 검색어를 입력하세요.')
          this.fn_BottomAlert('검색어를 입력하세요.')
          return
        }
      }

      const lv_Vm   = this
      const trnstId = 'txTSSAP06S2' //전화문자연결이력 조회 (일자조건 무시)
      const auth    = 'S'

      var cnsltNo = this.getStore('userInfo').getters.getUserInfo.userEno
      if (this.isManager) {
        cnsltNo = this.fofCnslt.key
        console.log('영업관리자인 경우 선택한 컨설턴트사번 변수에 할당 >> cnsltNo : ', cnsltNo)
      }

      let searchParams = this.fn_SearchParamBuilder()
          .setEno(cnsltNo) //모바일영업컨설턴트사번
          .setFlagMobslChnlCustId(0) //모바일영업채널고객ID 필터링 적용여부
          .setChnlCustId(this.selectedCustChnlCustId)//모바일영업채널고객ID
          .setFlagMobslCntcOccuYmd(0) //모바일영업접촉발생일자 필터링 적용여부
          .setMobslCntcOccuYmd('') //모바일영업접촉발생일자
          .setFlagPhclMemoCntnt(1) //통화메모내용 필터링 적용여부
          .setPhclMemoCntnt(this.searchText) //통화메모내용
          .setFlagMobslCustCntcId(0) //발신/수신/전체 필터링 적용여부
          .build()

      console.log('fn_SearchByMemo searchParams>>!', searchParams)

      this.searchResultText = this.searchText

      this.isLoading = true
      this.isSearchMemoActionDone = false

      this.post(lv_Vm, searchParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null) {
              if (response.body !== null && response.body.telHstrVOMap !== null) {
                let telHistoryObj = response.body.telHstrVOMap
                
                lv_Vm.searchCount = 0
                lv_Vm.searchTelHistoryList = [] //초기화
                lv_Vm.originSearchTelHistoryList = [] //초기화

                for (const key in telHistoryObj) {
                  let telHistoryListByDate = telHistoryObj[key]
                  const filteredTelHistoryList = lv_Vm.fn_GetFilteredList(telHistoryListByDate)
                  if (filteredTelHistoryList.length > 0) {
                    if (key.length == 8) { //key : yyyymmdd value
                      var telHistoryObjByDate = {}
                      var yyyymmdd = lv_Vm.fn_GetFormattedDateString(key)

                      telHistoryObjByDate['date'] = key
                      telHistoryObjByDate['formattedDate'] = yyyymmdd
                      telHistoryObjByDate['data'] = filteredTelHistoryList

                      lv_Vm.searchTelHistoryList.push(telHistoryObjByDate)
                      lv_Vm.originSearchTelHistoryList.push(telHistoryObjByDate)
                    }
                  }
                }

                lv_Vm.fn_GetSortListBySortCd(lv_Vm.sortCd)

                console.log('lv_Vm.searchTelHistoryList', lv_Vm.searchTelHistoryList)
                if (lv_Vm.searchTelHistoryList.length > 0) {
                  lv_Vm.fn_RevealSearchWord(lv_Vm)
                }
              }
            }
            lv_Vm.isLoading = false
            lv_Vm.isSearchMemoActionDone = true
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },
    /**
     * 고객명/메모 검색어로 전화활동 검색 시 사용하는 파라미터 객체 빌더
     */
    fn_SearchParamBuilder() {
      const searchParam = {}
      return {
        //모바일영업컨설턴트사번
        setEno(eno) {
          searchParam.eno = eno
          return this
        },
        //모바일영업채널고객ID 필터링 적용여부 (0:미적용, 1:적용)
        setFlagMobslChnlCustId (flagMobslChnlCustId) {
          searchParam.flagMobslChnlCustId = flagMobslChnlCustId
          return this
        },
        //모바일영업채널고객ID
        setChnlCustId (chnlCustId) {
          searchParam.chnlCustId = chnlCustId
          return this
        },
        //모바일영업접촉발생일자 필터링 적용여부 (0:미적용, 1:적용)
        setFlagMobslCntcOccuYmd(flagMobslCntcOccuYmd) {
          searchParam.flagMobslCntcOccuYmd = flagMobslCntcOccuYmd
          return this
        },
        //모바일영업접촉발생일자
        setMobslCntcOccuYmd(mobslCntcOccuYmd) {
          searchParam.mobslCntcOccuYmd = mobslCntcOccuYmd
          return this
        },
        //통화메모내용 필터링 적용여부 (0:미적용, 1:적용)
        setFlagPhclMemoCntnt(flagPhclMemoCntnt) {
          searchParam.flagPhclMemoCntnt = flagPhclMemoCntnt
          return this
        },
        //통화메모내용
        setPhclMemoCntnt(phclMemoCntnt) {
          searchParam.phclMemoCntnt = phclMemoCntnt
          return this
        },
        //발신/수신/전체 필터링 적용여부 (0:전체, 1:발신, 2:수신)
        setFlagMobslCustCntcId(flagMobslCustCntcId) {
          searchParam.flagMobslCustCntcId = flagMobslCustCntcId
          return this
        },
        //파라미터 객체 빌드
        build() {
          return searchParam
        }
      }
    },
    /**
     * 날짜 포맷팅
     * @param {string} yyyymmdd
     */
    fn_GetFormattedDateString (yyyymmdd) {
      console.log('fn_GetFormattedDateString', 'yyyymmdd', yyyymmdd)
      if (yyyymmdd.length == 8) {
        return yyyymmdd.substring(0, 4) + '.' + yyyymmdd.substring(4, 6) + '.' + yyyymmdd.substring(6)
      } else {
        console.log('fn_GetFormattedDateString error case : yyyymmdd', yyyymmdd)
        return ''
      }
    },
    /**
     * 고객 UI Name Card 호출 - 바텀시트 (전화활동 고객명 터치 시 호출됨)
     * @param {object} item - 전화활동 검색결과 목록에서 클릭한 전화활동 데이터 단건
     */
    fn_OpenCustNmUiCard (item) {  
      console.log('fn_OpenCustNmUiCard', 'item', item)
      let lv_Vm = this
      let nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPAP311P', //호출 화면 ID
        cnsltNo : this.userInfo.userId, //FC 사번
        custNm : item.custNm, //고객명
        contNo : '' //계약번호
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId) => {
        //Close 콜백 처리
        return
      })
    },
    /**
     * 알림 팝업 호출
     * @param {string} contents - 바텀얼럿 본문 내용
     */
    fn_BottomAlert (contents) {
      console.log('fn_BottomAlert', 'contents', contents)
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '알림',
          content: contents
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
    },

    /**
     * @function fn_GetSortListBySortCd
     * @name     데이터정렬함수
     * @description 전화활동 팝업 화면에서 정렬선택 시 해당 함수 호출
     */
    fn_GetSortListBySortCd (sortCd) {
      console.log('fn_GetSortListBySortCd', 'sortCd', sortCd)

      this.sortCd = this.$bizUtil.cloneDeep(sortCd)

      let type = sortCd.type
      let sort = sortCd.sort

      if (type == 'all') {
        this.searchTelHistoryList = this.$bizUtil.cloneDeep(this.originSearchTelHistoryList)
      } else if (type == 'receive' || type == 'send') {
        this.searchTelHistoryList = this.fn_GetListWithFilterBySR(type)
      }

      if (this.searchTelHistoryList.length == 1) {
        if (sort === 'ASC') {
          // 오름차순
          this.searchTelHistoryList[0]['data'] = this.searchTelHistoryList[0]['data'].sort((a, b) => a['mobslCntcTm'] - b['mobslCntcTm'])
        } else {
          // 내림차순
          this.searchTelHistoryList[0]['data'] = this.searchTelHistoryList[0]['data'].sort((a,b) => b['mobslCntcTm'] - a['mobslCntcTm'])
        }
      } else if (this.searchTelHistoryList.length > 1) {
        if (sort === 'ASC') {
          // 오름차순
          this.searchTelHistoryList = this.searchTelHistoryList.sort((a, b) => a['date'] - b['date'])
        } else {
          // 내림차순
          this.searchTelHistoryList = this.searchTelHistoryList.sort((a,b) => b['date'] - a['date'])
        }
      }

      this.searchCount = 0
      this.searchTelHistoryList.forEach(telHistoryByDate => {
        this.searchCount += telHistoryByDate['data'].length
      })
    },
    /**
     * @function fn_GetListWithFilterBySR
     * @param {string} type
     * @name 정렬선택 필터값(수신만보기/발신만보기)과 일치하는 데이터를 리턴한다.
     * @description 정렬선택 바텀시트에서 수신만보기 혹은 발신만보기 정렬 선택 시 해당 함수 호출
     */
    fn_GetListWithFilterBySR (type) {
      console.log('fn_GetListWithFilterBySR >> ', 'type', type)

      var filtered = []
      var SR = ''

      if (type == 'receive') {
        SR = '수신'
      } else if (type == 'send') {
        SR = '발신'
      }

      this.originSearchTelHistoryList.forEach(objByDate => {
        var receiveList = []

        objByDate['data'].forEach(listItem => {
          if (listItem.SR === SR) {
            receiveList.push(listItem)
          }
        })

        if (receiveList.length > 0) {
          var receiveObjByDate = {}
          receiveObjByDate['date'] = objByDate['date']
          receiveObjByDate['formattedDate'] = objByDate['formattedDate']
          receiveObjByDate['data'] = receiveList
          filtered.push(receiveObjByDate)
        }
      })

      return filtered
    },

    //////////////////////////////////////////////////
    // 관리자로 로그인 한 경우 사용되는 공통함수
    //////////////////////////////////////////////////

    /**
     * 사용자의 조직조회 권한 확인
     */
    fn_GetFofRol () {
      console.log('fn_GetFofRol >> ')
      this.isRoleCheckFirst = true
      this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack)
    },
    /**
     * 조직조회권한 세팅
     * @param {object} pData
     * @param {string} pData.isEntpwRolYn - 전사역할여부 (Y/N)
     * @param {string} pData.isHofRolYn - 사업부역할여부 (Y/N)
     * @param {string} pData.isDofRolYn - 지역단역할여부 (Y/N)
     * @param {string} pData.isEofRolYn - 지점역할여부 (Y/N)
     */
    fn_GetFofRolCallBack (pData) {
      console.log('fn_GetFofRolCallBack >> ', 'pData', pData)
      if (pData) {
        this.orgAuth = pData
        let sOrgAuth = pData.isEntpwRolYn + pData.isHofRolYn + pData.isDofRolYn + pData.isEofRolYn
        console.log( '-------------------' )
        console.log( sOrgAuth )
        console.log( 'HOF::' + this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
        console.log( 'DOF::' + this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
        console.log( 'FOF::' + this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
        console.log( this.getStore('userInfo').getters.getUserInfo )
        console.log( '-------------------' )
        // managerSearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        this.managerSearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
        this.managerSearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
        this.$bizUtil.selUserOrgList(this.managerSearchParam, this.fn_OrgSearchCallBack)
      }
    },
    /**
     * 사업부/지역단/지점/컨설턴트 조회 콜백함수
     * @param {object} rtnData - 드롭다운 선택에 따른 응답결과
     * @param {string} sType - 조직검색유형 (FOF: 사업부/지역단/지점, CNSLT: 컨설턴트)
     */
    fn_OrgSearchCallBack (rtnData, sType) {
      console.log('fn_OrgSearchCallBack >> ', 'rtnData', rtnData, 'sType', sType)
      if (sType === 'CNSLT') {
        //컨설턴트
        this.fofCnsltData.slice(0)
        this.fofCnsltData = rtnData
        this.isRoleCheckFirst = false
      } else {
        this.hofOrgData = rtnData[0]
        this.dofOrgData = rtnData[1]
        this.fofOrgData = rtnData[2]
        console.log('this.isRoleCheckFirst >> ', this.isRoleCheckFirst)
        console.log(this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo)
        console.log(this.getStore('userInfo').getters.getUserInfo.onpstDofNo)
        console.log(this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo)
        if (this.isRoleCheckFirst) {
          let isSelHof = false
          for ( let oData of rtnData[0] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo ) {
              this.hofOrg = oData
              isSelHof = true
              break
            }
          }
          if (!isSelHof) this.hofOrg = {key: '00000000', label: '전사'}

          let isSelDof = false
          for ( let oData of rtnData[1] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstDofNo ) {
              this.dofOrg = oData
              isSelDof = true
              break
            }
          }
          if (!isSelDof || !isSelHof) this.dofOrg = {key: '0', label: '전체'}

          let isSelFof = false
          for ( let oData of rtnData[2] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo ) {
              this.fofOrg = oData
              isSelFof = true
              break
            }
          }
          if (!isSelFof || !isSelDof) this.fofOrg = {key: '0', label: '전체'}
          
          this.fn_GetFofCnsltData(false)
        }
      }
    },
    /**
     * 사업부선택
     * @param {string} param - 사업부번호
     */
    fn_GetDofData (param) {
      console.log('fn_GetDofData >> 사업부선택', 'param', param)
      if (param) {
        this.managerSearchParam.hofOrgNo = this.hofOrg.key
        if (this.dofOrgData) { this.dofOrgData.slice(0) }
        if (this.fofOrgData) { this.fofOrgData.slice(0) }
        if (this.fofCnsltData) { this.fofCnsltData.slice(0) }
        this.dofOrg = {key: '0', label: '전체'}
        this.fofOrg = {key: '0', label: '전체'}
        this.fofCnslt = {key: '0', label: '전체'}
        if (this.hofOrg.key !== '0' && this.hofOrgData.length > 1 ) {
          this.$bizUtil.selListDofOrg(this.managerSearchParam, this.fn_OrgSearchCallBack)
        }
      }
    },
    /**
     * 지역단선택
     * @param {string} param - 지역단번호
     */
    fn_GetFofData (param) {
      console.log('fn_GetFofData >> 지역단선택', 'param', param)
      if (param) {
        this.managerSearchParam.hofOrgNo = this.hofOrg.key
        this.managerSearchParam.dofOrgNo = this.dofOrg.key
        if (this.fofOrgData) { this.fofOrgData.slice(0) }
        if (this.fofCnsltData) { this.fofCnsltData.slice(0) }
        this.fofOrg = {key: '0', label: '전체'}
        this.fofCnslt = {key: '0', label: '전체'}
      }
      this.$bizUtil.selListFofOrg(this.managerSearchParam, this.fn_OrgSearchCallBack)
    },
    /**
     * 지점선택
     * @param {string} param - 지점번호
     */
    fn_GetFofCnsltData (param) {
      console.log('fn_GetFofCnsltData >> 지점선택', 'param', param, 'managerSearchParam', this.managerSearchParam)
      if (param) {
        this.managerSearchParam.hofOrgNo = this.hofOrg.key
        this.managerSearchParam.dofOrgNo = this.dofOrg.key
        this.managerSearchParam.fofOrgNo = this.fofOrg.key
        this.managerSearchParam.orgNo = this.fofOrg.key
        if (this.fofCnsltData) { this.fofCnsltData.slice(0) }
        this.fofCnslt = {key: '0', label: '전체'}
        this.$bizUtil.selListFofCnslt(this.managerSearchParam, this.fn_OrgSearchCallBack)
      } else {
        this.managerSearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
        this.managerSearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
        this.$bizUtil.selListFofCnslt(this.managerSearchParam, this.fn_OrgSearchCallBack)
      }
    },
    //////////////////////////////////////////////////
    // 관리자로 로그인 한 경우 사용되는 공통함수 END
    //////////////////////////////////////////////////

  }
}
</script>
