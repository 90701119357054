<template>

  <ur-page-container class="msp myCallHistory" :show-title="true" title="전화활동" type="page" @on-header-left-click="fn_back" :action-type="actionSlot" :customer="fcData.label" :customerID="fcData.key">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1">
              <!--datepicker-->
              <ur-box-container alignH="center" componentid="" direction="row" class="point-date-wrap myCallHistoryCalenWrap"> 
                <mo-icon icon="msp-arrow-pre" @click="fn_date('pre')">msp-arrow-pre</mo-icon>
                <div class="monthDateOnlyValue">{{getMonthDateValue}}</div><!-- 2024.10.07 :chan -->
                <mo-date-picker class="full monthDateOnlyPicker" init="today" v-model="dateValue" :input-Type="'YYYYMMDD'" :model-type="'YYYYMMDD'"/><!-- 2024.10.07 :chan -->
                <!-- <mo-month-picker class="full" placeholder="YY-MM" v-model="srchDate"/> -->
                <mo-icon icon="arrow-ttl" v-if="dateValue < todayDate" @click="fn_date('next')">arrow-ttl</mo-icon>
              </ur-box-container>
              <!--//datepicker-->

              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3">
                    <div class="fexTy1">
                      <span class="sum" style="margin-top:0px;">총 <strong class="crTy-blue3">{{ todayCnt }}</strong> 건</span>
                    </div>
                    <msp-bottom-select 
                      ref="bottomSelect" 
                      :items="sortCdList" 
                      v-model="sortCd.value" 
                      @:itemValue="'value'" 
                      :itemText="'text'" 
                      underline class="ns-dropdown-sheet maxW130 type-1 fs14rem" 
                      placeholder="정렬선택" 
                      bottom-title="정렬선택" 
                      closeBtn/>
                  </div>
                </div>
              </mo-list-item>

              <template v-if="callList.length > 0">
                <mo-list-item v-for="(item,idx) in callList" :key="idx"> 
                  <template>
                    <ur-box-container alignV="start" componentid="" direction="column" class="round-box itemBox" :class="{'bgcolor-1' : item.SR === '발신', 'bgcolor-blue' : item.SR === '수신'}">
                      <div class="myCallHistoryNameWrap">
                        <span class="myCallHistoryName" @click="fn_OpenCustNmUiCard(item)">{{item.custNm}}</span>
                        <div class="myCallHistoryBtnWrap" v-if="isOwnerSearch">
                          <mo-button class="blackWH myCallHistoryBtnModify" @click="fn_memoSave(item)">{{item.memo ? '수정' : '입력' }}</mo-button>
                          <mo-icon icon="msp-close-bold" class="myCallHistoryBtnClose"  @click="fn_BottomConfirm('삭제하시겠습니까?', item.CheckSn, item.chnlCustId )">msp-close-bold</mo-icon>
                        </div>
                      </div>
                      <div class="mt5">
                        <span class="myCallHistorySR" :class="{'myCallHistorySRBlue':item.SR==='수신'}">{{item.SR}}</span>
                        <span class="myCallHistoryTime">{{item.CntcCntnt}}</span>
                      </div>
                      <div class="myCallHistoryMemo" v-if="item.memo" v-html="item.phclMemoCntnt"></div>
                    </ur-box-container>
                  </template>
                </mo-list-item>
              </template>
              <template v-else>
                <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                  <mo-list-item ref="expItem">
                    <div class="list-item__contents">
                      <div class="list-item__contents__info vh50">
                        <span class="ns-ftcr-gray"><p class="mt20">사랑온으로 전화통화를 하면<br>전화활동 이력을 볼 수 있어요</p></span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </template>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  import MSPCM139P from '@/ui/cm/MSPCM139P' // 전화메모 추가, 수정
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP311P from '@/ui/ap/MSPAP311P' // 전체고객 목록 검색
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import apConstants from '@/config/constants/apConstants'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP310M",
    screenId: "MSPAP310M",
    components: {
      MSPAP311P,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_back)

      let tmp = this.$route.params

      if(this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y' && Object.keys(tmp).length !== 0) { // MSPBC003M에서 타 사번 조회된 상태로넘어온 경우
        if(!this.$bizUtil.isEmpty(tmp.cnsltNo)) {
          this.cnsltNo = tmp.cnsltNo
        } else if (tmp.pData?.key){
          this.cnsltNo = tmp.pData.key
          this.fcData = tmp.pData
        } else {
          this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
        }
      } else {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.cnsltNo
      }

      let template  = ''
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        template = "<mo-icon @click='fn_OpenMSPAP311P'>search</mo-icon>"
      } else {
        template = "<mo-icon @click='fn_OpenMSPAP311P'>search</mo-icon>"
      }

      let lv_vm = this
      // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template: template,
          methods: {
            // 사용하지 않음
            fn_ManagerSearch() {
              console.log('fn_ManagerSearch')
              this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
                properties: {
                  pPage: 'MSPAP310M',
                  pData: this.fcData
                },
                listeners: {
                  onPopupSearch: (pItem) => {
                    console.log('fn_ManagerSearch onPopupSearch 컨설턴트 >> ' , pItem)

                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                    if(pItem != null && pItem != undefined){
                      lv_vm.cnsltNo = pItem.key

                      lv_vm.fcData = pItem
                      lv_vm.dateValue = moment(new Date()).format('YYYYMMDD')
                    }
                  },
                  onPopupClose: () => {
                    console.log('fn_ManagerSearch onPopupClose')
                    // 모달 닫기
                    this.$bottomModal.close(this.popupAP106)
                  }
                }
              })
            },

            /******************************************************************************
             * Function명: fn_OpenMSPAP311P
             * 설명: 고객명/메모 검색 팝업 호출
             ******************************************************************************/
            fn_OpenMSPAP311P () {
              console.log('fn_OpenMSPAP311P')
              this.popup311 = this.$bottomModal.open(MSPAP311P, {
                properties: {
                  pPage: 'MSPAP310M',
                  pIsMng : true,
                  fcDtlData: lv_vm.fcData, // fc 상세 데이터
                },
                listeners: {
                  onPopupClose: (pData, pType) => {
                    console.log('fn_OpenMSPAP311P onPopupClose', 'pData', pData, 'pType', pType)
                    // 모달 닫기
                    this.$bottomModal.close(this.popup311)

                    if (lv_vm.isManager) {
                      if ( pData != null && !this.$bizUtil.isEmpty(pData.key) ) {
                        console.log('fn_OpenMSPAP311P onPopupClose 팝업에서 선택한 컨설턴트 정보 세팅 : pData', pData)

                        //2025.04.02 영업관리자가 컨설턴트 선택 없이 전화활동 진입 > 검색 > 특정 FC 선택 없이 이전화면 돌아가는 경우 상단에 지점장 이름 노출되는 부분 수정
                        if (lv_vm.getStore('userInfo').getters.getUserInfo.cnsltNo != pData.key) { 
                          lv_vm.cnsltNo = pData.key
                          lv_vm.fcData = pData
                        }
                      }
                    }

                    if (lv_vm.dateValue == null) {
                      console.log('fn_OpenMSPAP311P onPopupClose 예외처리 - 날짜정보 없는 경우 오늘 날짜 세팅')
                      lv_vm.dateValue = moment(new Date()).format('YYYYMMDD')
                    }

                    if (pType != null && pType != undefined) {
                      if (pType == 'beforeDestroy') {
                        // 고객명 조회
                        lv_vm.fn_callHis(lv_vm.dateValue)
                      }
                    }
                  }
                }
              })
            }
          }
        }
      }

      this.sortCd = this.$bizUtil.cloneDeep(this.sortCdList[0])
    },
    mounted() {
      // 오늘 날짜로 설정
      this.dateValue = moment(new Date()).format('YYYYMMDD')
    },
    beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_back)
  },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        callList           : [],                                                         // 전화이력 list
        originCallList     : [],                                                         // 전화이력 list (필터링 적용전 원본)
        todayDate          : moment(new Date()).format('YYYYMMDD'),                      // 오늘날짜
        todayCnt           : 0,                                                          // 당일 전화이력 건수
        dateValue          : '',                                                         // 달력
        getMonthDateValue  : '',                                                         // 선택날짜
        cnsltNo            : '',                                                         // 컨설턴트사번
        fcData             : '',
        pOrgData310M       : {}, // 최근 조직 조회 데이터    
        
        /* 계약 정렬 선택값 */
        sortCdList: apConstants.CALL_ACTIVITY_SORT_CD_LIST,
        sortCd: {}, // 선택된 정렬 데이터// 조회 FC
      };
    },

    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      /**
       * 전화활동 날짜 변경 시 호출되며, 변경된 날짜 당일의 전화활동 이력 데이터를 조회합니다.
       */
      dateValue() {
        let month = this.dateValue.substr(4,2)
        let date = this.dateValue.substr(6)
        this.getMonthDateValue = month + '월' + date + '일'

        this.fn_callHis(this.dateValue)
      },
      reload() {
        if(this.getStore('apStore').getters.getState.reload) {
          this.getStore('apStore').dispatch('SRN_RELOAD', false)
          this.fn_callHis(this.dateValue)
        }
      },
      /**
       * 전화활동 정렬선택 필터 값이 변경되면 호출됩니다.
       * @param {string} value - 정렬선택 값 (1:시간순 보기, 2:최신순 보기, 3:수신만 보기, 4:발신만 보기)
       */
      'sortCd.value'(value) {
        let selSortCd = null
        selSortCd = this.sortCdList.filter(item => {
          return item.value === value
        })
        this.fn_GetSortListBySortCd(selSortCd[0])
      }
    },
    computed: {
      reload() {
        return this.getStore('apStore').getters.getState.reload
      },
      /**
       * 현재 전화활동 내역을 조회한 컨설턴트가 전화활동을 수행한 컨설턴트 본인인지 확인
       * @returns {boolean}
       */
      isOwnerSearch () {
        if (this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
          return false
        } else {
          return true
        }
      },
      /**
       * 지점장 이상 권한인지 확인
       * @returns {boolean}
       */
      isManager () {
        if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
          return true
        } else {
          return false
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_callHis
       * 설명: 전화이력조회
       *********************************************************/
      fn_callHis(date, reload, flag) {

        console.log('fn_callHis', 'date', date, 'reload', reload, 'flag', flag)

        const lv_Vm   = this 
        const trnstId = 'txTSSAP06S1' 
        const auth    = 'S'
        
        lv_Vm.todayCnt = 0
        lv_Vm.callList = []
        lv_Vm.originCallList = []

        let pParams   = {
          mobslCntcOccuYmd: date,
          eno             : lv_Vm.cnsltNo,
        }

        console.log('fn_callHis param>>!', pParams)
        lv_Vm.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            console.log('fn_callHis response.body', response.body)
            if (response.body !== null && response.body.telHstrVO !== null && response.body.telHstrVO.length > 0) {
            let lv_CustList = response.body.telHstrVO

            // 고객접촉ID 분류
            lv_CustList.forEach(item => {
              if (item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'SEND' || item.mobslCustCntcId === 'RECI') {
                let cntcId = ''
                if(item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'SEND') cntcId = '발신'
                if(item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'RECI') cntcId = '수신'

                let phclMemoCntnt = ''
                if(!lv_Vm.$bizUtil.isEmpty(item.phclMemoCntnt)) {
                  phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                }
                let tmpObj = {
                  'custNm'       : item.custNm,
                  'CntcCntnt'    : item.mobslCntcCntnt,
                  'memo'         : item.phclMemoCntnt,
                  'SR'           : cntcId,
                  'phclMemoCntnt': phclMemoCntnt,
                  'CheckSn'      : item.mobslCntcCheckSn,
                  'chnlCustId'   : item.mobslChnlCustId,
                  'mobslCntcTm'  : item.mobslCntcTm,
                }
                lv_Vm.callList.push(tmpObj)
                lv_Vm.originCallList.push(tmpObj)
              }
            });

            console.log('fn_callHis callList', lv_Vm.callList)
            console.log('fn_callHis originCallList', lv_Vm.originCallList)

            lv_Vm.fn_GetSortListBySortCd(lv_Vm.sortCd)

            if (reload) {
              setTimeout(function () {
                let alertProps = {
                    title: '',
                    msg: flag ? '수정되었습니다.' : '저장되었습니다.'
                }
                lv_Vm.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
              },1000)
            }
          }
        })
      },

      /*********************************************************
       * Function명: fn_back
       * 설명: 뒤로가기
       *********************************************************/
      fn_back() {
        console.log('fn_back', 'this.isOwnerSearch', this.isOwnerSearch, 'this.fcData', this.fcData)
        // 화면 이동 시, 검색사번 유지
        if(!this.isOwnerSearch) {
          this.getStore('bcStore').dispatch('FC_DATA', this.fcData)
        }
        this.$router.go(-1)
      },

      /*********************************************************
       * Function명: fn_date
       * 설명: 날짜 이동
       *********************************************************/
      fn_date(btn) {
        console.log('fn_date', 'btn', btn)
        let lastDay = String(new Date(this.dateValue.substr(0,4), this.dateValue.substr(4,2) , 0).getDate())

        // < 클릭
        if(btn === 'pre') {
          if (this.dateValue.substr(4,2) === '01' && this.dateValue.substr(6) === '01') {
            this.dateValue = String(Number(this.dateValue.substr(0,4)) - 1) + '1231'
          } 
          else if(this.dateValue.substr(6) === '01') {
            this.dateValue = String(Number(this.dateValue.substr(0,6)) - 1) + lastDay
          }
          else {
            this.dateValue = String(Number(this.dateValue) - 1)  
          }
        }

        // > 클릭
        if(btn === 'next') {
          if (this.dateValue.substr(4,2) === '12' && this.dateValue.substr(6) === '31') {
            this.dateValue = String(Number(this.dateValue.substr(0,4)) + 1) + '0101'
          } 
          else if(this.dateValue.substr(6) === lastDay) {
            this.dateValue = String(Number(this.dateValue.substr(0,6)) + 1) + '01'
          }
          else {
            this.dateValue = String(Number(this.dateValue) + 1)   
          }
        }
      },

      /*********************************************************
       * Function명: fn_memoSave
       * 설명: 메모저장/수정
       *********************************************************/
      fn_memoSave(item) {
        console.log('fn_memoSave', 'item', item)
        let lv_Vm = this

        let popup139 = this.$bottomModal.open(MSPCM139P, {
          properties: {
            pMobslCntcCheckSn: item.CheckSn,
            pChnlCustId      : item.chnlCustId,
            custNm           : item.custNm,
            showTime         : item.CntcCntnt,
            memoCntnt        : item.memo
          },

          listeners: {
            onPopupClose: () => {
              // 모달 닫기
              console.log('MSPCM290D onPopupClose')
              this.$bottomModal.close(popup139)
            },

            onPopupConfirm: (pData) => {
              // 모달 닫기
              this.$bottomModal.close(popup139)
              this.fn_callHis(lv_Vm.dateValue, true, pData)
            },
          }
        },
        {
          properties: {
            preventTouchClose: true,
          }
        })
      },
      
      /******************************************************************************
       * Function명 : fn_BottomConfirm
       * 설명       : Confirm 팝업 호출(확인, 취소)
       ******************************************************************************/
      fn_BottomConfirm (contents, CheckSn, chnlCustId) {
        console.log('fn_BottomConfirm', 'contents', contents, 'CheckSn', CheckSn, 'chnlCustId', chnlCustId)
        this.lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title        : '알림',
            content      : contents,
            title_pos_btn: "삭제",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_ConfirmPop);
              this.fn_deleteMemo(CheckSn, chnlCustId)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_ConfirmPop);
            }
          }
        })
      },
      /*****************************************************************************
       * Function명 : fn_deleteMemo
       * 설명       : 전화메모 단건 삭제
       ******************************************************************************/
      fn_deleteMemo(CheckSn, chnlCustId) {
        console.log('fn_deleteMemo', 'CheckSn', CheckSn, 'chnlCustId', chnlCustId)

        const lv_Vm   = this 
        const trnstId = 'txTSSCM58D3' 
        const auth    = 'S'

        let pParams = {
          mobslCntcCheckSn: CheckSn,
          mobslChnlCustId : chnlCustId,
          mobslCnsltEno   : this.getStore('userInfo').getters.getUserInfo.userId,
        }
        console.log('fn_deleteMemo param>>!', pParams)

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response?.body && response.body.rsltYn === '1') {
              console.log('fn_deleteMemo 삭제완료')
              lv_Vm.fn_callHis(lv_Vm.dateValue)
            } else {
              let msg = response.msgComm.msgDesc
              lv_Vm.$cmUtil.fn_OpenAlert(true, msg)
            }
          })
      },
      /******************************************************************************
       * Function명 : fn_OpenCustNmUiCard
       * 설명       : 고객 UI Name Card 호출 - 바텀시트 (전화활동 고객명 터치 시 호출됨)
       ******************************************************************************/
      fn_OpenCustNmUiCard (item) {
        console.log('fn_OpenCustNmUiCard', 'item', item)

        let lv_Vm = this
        let nmUiObject = {
          chnlCustId : item.chnlCustId, //고객채널 ID
          parentId   : 'MSPAP310M', //호출 화면 ID
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.userId, //FC 사번
          custNm : item.custNm, //고객명
          contNo : '' //계약번호
        }

        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId) => {
          //Close 콜백 처리
          return
        })
      },

      /**
       * @function fn_GetSortListBySortCd
       * @name     데이터정렬함수
       * @description 전화활동 화면에서 정렬선택 시 해당 함수 호출
       */
      fn_GetSortListBySortCd(sortCd) {
        console.log('fn_GetSortListBySortCd', 'sortCd', sortCd)

        this.sortCd = this.$bizUtil.cloneDeep(sortCd)

        let type = sortCd.type
        let sort = sortCd.sort

        if (type == 'all') {
          this.callList = this.$bizUtil.cloneDeep(this.originCallList)
        } else if (type == 'receive' || type == 'send') {
          this.callList = this.fn_GetListWithFilterBySR(type)
        }

        if (sort === 'ASC') {
          // 오름차순
          this.callList = this.callList.sort((a, b) => a['mobslCntcTm'] - b['mobslCntcTm'])
        } else {
          // 내림차순
          this.callList = this.callList.sort((a,b) => b['mobslCntcTm'] - a['mobslCntcTm'])
        }

        this.todayCnt = this.callList.length
      },

      /**
       * @function fn_GetListWithFilterBySR
       * @param    {string} type
       * @name     정렬선택 필터값과 일치하는 데이터를 리턴한다.
       * @description 정렬선택 바텀시트에서 수신만보기 혹은 발신만보기 정렬 선택 시 해당 함수 호출
       */
      fn_GetListWithFilterBySR (type) {
        console.log('fn_GetListWithFilterBySR', 'type', type)

        var filtered = []
        var SR = ''

        if (type == 'receive') {
          SR = '수신'
        } else if (type == 'send') {
          SR = '발신'
        }

        this.originCallList.forEach(telHstrVO => {
          if (telHstrVO.SR === SR) {
            filtered.push(telHstrVO)
          }
        })

        return filtered
      }

    }
  };
</script>